@font-face {
    font-family: 'Open Sans';
    src: url('/s~opensans/2014/OpenSans-Regular-webfont.eot');
    src: url('/s~opensans/2014/OpenSans-Regular-webfont.eot?#iefix') format('embedded-opentype'),
    url('/s~opensans/2014/OpenSans-Regular-webfont.woff') format('woff'),
    url('/s~opensans/2014/OpenSans-Regular-webfont.ttf') format('truetype'),
    url('/s~opensans/2014/OpenSans-Regular-webfont.svg#open_sansregular') format('svg');
    font-weight: normal;
    font-style: normal;

}
@font-face {
  font-family: 'OpenSansRegular';
  src: url('/s~opensans/2014/OpenSans-Regular-webfont.eot');
  src: url('/s~opensans/2014/OpenSans-Regular-webfont.eot?#iefix') format('embedded-opentype'),
  url('/s~opensans/2014/OpenSans-Regular-webfont.woff') format('woff'),
  url('/s~opensans/2014/OpenSans-Regular-webfont.ttf') format('truetype'),
  url('/s~opensans/2014/OpenSans-Regular-webfont.svg#open_sansregular') format('svg');
  font-weight: normal;
  font-style: normal;

}
@font-face {
    font-family: 'OpenSansBold';
    src: url('/s~opensans/2014/OpenSans-Bold-webfont.eot');
    src: url('/s~opensans/2014/OpenSans-Bold-webfont.eot?#iefix') format('embedded-opentype'),
    url('/s~opensans/2014/OpenSans-Bold-webfont.woff') format('woff'),
    url('/s~opensans/2014/OpenSans-Bold-webfont.ttf') format('truetype'),
    url('/s~opensans/2014/OpenSans-Bold-webfont.svg#open_sansbold') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'OpenSansLight';
    src: url('/s~opensans/2014/OpenSans-Light-webfont.eot');
    src: url('/s~opensans/2014/OpenSans-Light-webfont.eot?#iefix') format('embedded-opentype'),
    url('/s~opensans/2014/OpenSans-Light-webfont.woff') format('woff'),
    url('/s~opensans/2014/OpenSans-Light-webfont.ttf') format('truetype'),
    url('/s~opensans/2014/OpenSans-Light-webfont.svg#open_sanslight') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('/s~opensans/2014/opensans-extrabold-webfont.eot');
  src: url('/s~opensans/2014/opensans-extrabold-webfont.eot?#iefix') format('embedded-opentype'),
  url('/s~opensans/2014/opensans-extrabold-webfont.woff2') format('woff2'),
  url('/s~opensans/2014/opensans-extrabold-webfont.woff') format('woff'),
  url('/s~opensans/2014/opensans-extrabold-webfont.ttf') format('truetype'),
  url('/s~opensans/2014/opensans-extrabold-webfont.svg#open_sansextrabold') format('svg');
  font-weight: 800;
  font-style: normal;

}

@font-face {
  font-family: 'OpenSansExtraBold';
  src: url('/s~opensans/2014/opensans-extrabold-webfont.eot');
  src: url('/s~opensans/2014/opensans-extrabold-webfont.eot?#iefix') format('embedded-opentype'),
  url('/s~opensans/2014/opensans-extrabold-webfont.woff2') format('woff2'),
  url('/s~opensans/2014/opensans-extrabold-webfont.woff') format('woff'),
  url('/s~opensans/2014/opensans-extrabold-webfont.ttf') format('truetype'),
  url('/s~opensans/2014/opensans-extrabold-webfont.svg#open_sansextrabold') format('svg');
  font-weight: normal;
  font-style: normal;

}