.back2top{
  display                           : none;
  bottom                            : 100px;
  height                            : 50px;
  position                          : fixed;
  right                             : 10px;
  width                             : 50px;
  z-index                           : 9999;
  &.default {
    background-color                : rgba(0, 0, 0, 0.5);
    background-repeat               : no-repeat; background-position: center;
    background-image                : url("/s~back2top/2015/back2top.png");
    border-radius                   : 7px;
    background-size                 : 35px 35px;
  }
}
