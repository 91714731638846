.shopping-cart-wrap{
  display: flex;
  span{margin: 0 5px;}
  figure {margin: 0;}
  & > a {
    display: flex;
  }
}

.shopping-cart-preview{
  display: flex;
  flex-direction: column;
}
.button-wrap {
  @each $size in $breakpoint-classes {
    @include breakpoint($size) {
      @each $align in (left, right, center, full) {
        @if $size != 'small' {
          @if $align == 'full' {
            &[data-align-#{$size}="#{$align}"] {
              text-align: center;
              .button {
                display: block;
              }
              button.button {
                width: 100%;
              }
            }
          } @else {
            &[data-align-#{$size}="#{$align}"] {
              text-align: $align;
              .button {
                display: inline-block;
              }
            }
          }
        } @else {
          @if $align == 'full' {
            &[data-align="#{$align}"] {
              text-align: center;
              .button {
                display: block;
              }
              button.button {
                width: 100%;
              }
            }
          } @else {
            &[data-align="#{$align}"] {
              text-align: $align;
              .button {
                display: inline-block;
              }
            }
          }
        }
      }
    }
  }
  a.button, button.button {
    margin: 0;
  }
}

.shopping-cart-wrap {
  align-items: center;
  .cart-panel{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display:flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
  }
  &[data-align="left"] {
    justify-content: flex-start;
    flex: 1;
  }
  &[data-align="center"] {
    justify-content: center;
    flex: 1;
  }
  &[data-align="right"] {
    justify-content: flex-end;
    flex: 1;
  }

  @media #{$medium-up} {
    &[data-medium-align="left"] {
      justify-content: flex-start;
      flex: 1;
    }
    &[data-medium-align="center"] {
      justify-content: center;
      flex: 1;
    }
    &[data-medium-align="right"] {
      justify-content: flex-end;
      flex: 1;
    }
  }

  @media #{$large-up} {
    &[data-large-align="left"] {
      justify-content: flex-start;
      flex: 1;
    }
    &[data-large-align="center"] {
      justify-content: center;
      flex: 1;
    }
    &[data-large-align="right"] {
      justify-content: flex-end;
      flex: 1;
    }
  }
}


.me-CartAddButton form.checkout2Qty {
  display: flex;
  &[data-align='left']{
    justify-content: flex-start;
  }
  &[data-align='center']{
    justify-content: center;
  }
  &[data-align='right']{
    justify-content: flex-end;
  }

  @media #{$medium-up} {
    &[data-align-medium='left']{
      justify-content: flex-start;
    }
    &[data-align-medium='center']{
      justify-content: center;
    }
    &[data-align-medium='right']{
      justify-content: flex-end;
    }
  }
  @media #{$large-up} {
    &[data-align-large='left']{
      justify-content: flex-start;
    }
    &[data-align-large='center']{
      justify-content: center;
    }
    &[data-align-large='right']{
      justify-content: flex-end;
    }
  }
}