.me-admin .me-BaseButton {
  &[data-align='left'] {
    text-align: left;
  }
  &[data-align='center'] {
    text-align: center;
  }
  &[data-align='right'] {
    text-align: right;
  }
  &[data-align='full'] {
    text-align: center;
    .button {
      display: block;
      width: 100%;
    }
  }
  .button {
    margin: 0;
  }
}

.me-style-option .checkboard {
  background-image: url("/s~buildcuststyle/0/transparent.png");
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
}
