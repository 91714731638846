.me-Instafeed .row {
  display: flex;
  .item {
    //flex-grow: 1;
    &[data-pad="none"] {
      padding: 0;
    }
    &[data-pad="small"] {
      padding: 0 5px;
    }
    &[data-pad="large"] {
      padding: 0 15px;
    }
  }
  .item a {
    display: block;
    width: 100%;
    .mask {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%;
    }
    &:hover {
      .mask {
        background-color: rgba(0, 0, 0, 0.2);
      }
    }
    .instafeed-image {
      padding-bottom: 100%;
      background-size: cover;
      position: relative;
    }
  }
}
