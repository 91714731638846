span.word {
  border: 1px solid;
  padding: 5px 10px;
  display: inline-block;
  border-radius: 20px;
  margin: 0 5px;
  cursor: pointer;
}

div.blank{
  display: inline-block;
  width: auto;
  text-align: center;
  position: relative;
  padding-right: 15px;
  & > .remove {
    content: 'x';
    position: absolute;
    right: 3px;
    top: 0;
    border: 1px solid;
    border-radius: 50%;
    font-size: 12px;
    padding: 0 5px;
    cursor: pointer;
  }
  &:empty {
    min-width: 100px;
    min-height: 30px;
    background: #e8e8e8;
    vertical-align: middle;
    padding-right: 0;
  }
}

.column.radio-button{
  & > label {
    display: flex !important;
    align-items: center;
    & > input {
      opacity: 0;
      overflow: hidden;
      width: 0;
      height: 0;
    }
  }
}

i{
  &.checked { display: none; }
  &.uncheck { display: block; }
}

input:checked ~ i {
  &.checked { display: block; }
  &.uncheck { display: none; }
}

.hide-for-all {
  opacity: 0;
  overflow: hidden;
  max-width: 0;
  max-height: 0;
  padding: 0;
  margin: 0;
}