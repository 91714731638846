// Foundation for Sites by ZURB
// foundation.zurb.com
// Licensed under MIT Open Source

////
/// @group float
////

@mixin foundation-float-classes {
  .float-left {
    float: left !important;
  }

  .float-right {
    float: right !important;
  }

  .float-center {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .clearfix {
    @include clearfix;
  }
}
