//// Global Site Settings
//// ====================
//$METhemeName: site !default;
///*Common Builder Vars*/
$MEDefaultFontFamily: inherit !default; //Default Fontsasscont
$MEDefaultFontSize: 16px !default; //Default Font Size
//
//$MEDefaultBlockPad: 20px !default; //Default gap between blocks
//$MEDefaultBlockPadSmall: 5px !default; // Default small gap between blocks
//$MEDefaultBLockPadNone: 0px !default;  // do not need gap between blocks;
//
//$MEDefaultFg: #555555 !default; //Main Foreground Colour
//$MEDefaultBg: #07c9de !default; //Main Background Colour
//$MEDefaultLink: grey !default; //Main Link Colour
//$MEDefaultLinkHover: blue !default; //Main Link (hovered) Colour
//
//$MEDefaultMaxWidth: 1280px !default; //Maximum Row Width
//$MEDefaultBlockPad: 20px !default; //Default gap between blocks
//$MEDefaultBlockPadSmall: 5px !default; // Default small gap between blocks
//$MEDefaultBLockPadNone: 0px !default;  // do not need gap between blocks;
//$MEDefaultParaPad: 10px !default; //Default gap between blocks
//$MEDefaultLineHeight : 20px !default;
//
//$MEDefaultH1 : 40px !default;
//$MEDefaultH2 : 35px !default;
//$MEDefaultH3 : 30px !default;
//$MEDefaultH4 : 25px !default;
//$MEDefaultH5 : 22px !default;
//$MEDefaultH6 : 18px !default;
//%h1 {font-size: $MEDefaultH1;}
//%h2 {font-size: $MEDefaultH2;}
//%h3 {font-size: $MEDefaultH3;}
//%h4 {font-size: $MEDefaultH4;}
//%h5 {font-size: $MEDefaultH5;}
//%h6 {font-size: $MEDefaultH6;}
//
$MEInvertedFg: white !default;  // Inverted Colour (Text)
$MEInvertedLink: lightgrey !default;   // Inverted Link Colour
$MEInvertedLinkHover: yellow !default; // Inverted Link (hover) Colour
$MEInvertedBg: black !default;  // Inverted Background
$MEInvertedBgHover: white !default;
//$MEPrimaryFg: white !default;          // Primary Colour (Text)
//$MEPrimaryLink: white !default;       // Primary Link Colour
//$MEPrimaryLinkHover: white !default;    // Primary Link (hover) Colour
//$MEPrimaryBg: darkblue !default;          // Primary Background
//$MESecondaryFg: black !default;        // Secondary Color (Text)
//$MESecondaryLink: darkblue !default;   // Secondary Link Colour
//$MESecondaryLinkHover: blue !default;  // Secondary Link (hover) Colour
//$MESecondaryBg: yellow !default;         // Secondary Background
$text-select-colour: #B1D7FE !default;  // text background when selected


//Responsive Menu Icon
$menu-bar-height: 8px !default;
$menu-bar-space: 5px !default;
$meun-top-bar-position: 11px !default;
$menu-bottom-bar-position: -14px !default;
$menu-bar-thin-height: 3px !default;
$menu-bar-thin-space: 7px !default;
$meun-top-bar-thin-position: 5px !default;
$menu-bottom-bar-thin-position: -15px !default;
