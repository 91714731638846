

@mixin foundation-everything($flex: false) {
  @if $flex {
    $global-flexbox: true !global;
  }

   @include foundation-global-styles;
  @if not $flex {
    @include foundation-grid;
  }
  @else {
    @include foundation-flex-classes;
    @include foundation-flex-grid;
  }
  @include foundation-text-alignment;
  @include foundation-forms;
  @include foundation-callout;
  @include foundation-drilldown-menu;
  //@include foundation-button;
  // More lightweight button style

  .button {
    @include button($expand: false, $background: none, $background-hover:none, $color:inherit, $style:false);
  }

  @include foundation-button-group;
  @include foundation-flex-video;
  //@include foundation-switch;
  @include foundation-table;
  @include foundation-visibility-classes;
  @include foundation-float-classes;

  .me-block.row .me-block.row { /*Fix for nested rows*/
    margin-left:auto;
    margin-right:auto;
  }
}

@include foundation-everything(true);