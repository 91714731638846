body { background-color: $COL1; }
body { color: $COL7; }
[data-pad="0"] { margin-top: 20px; }

[data-pad="1"] { margin-top: 5px; }

h1 {
color: $COL12;
font-family: 'Asap';
font-weight: 700;
font-style: normal;
line-height: 1;
text-transform: uppercase;
font-size: 45px;

@media #{$medium-up} {
font-size: 35px;

}
@media #{$large-up} {
font-size: 60px;

}
}
h2 {
color: $COL13;
font-family: 'Asap';
font-weight: 700;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
font-size: 25px;

@media #{$large-up} {
font-size: 40px;

}
}
h3 {
color: $COL14;
font-family: 'Asap';
font-weight: 700;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
font-size: 25px;

@media #{$medium-up} {
font-size: 20px;

}
@media #{$large-up} {
font-size: 30px;

}
}
h4 {
color: $COL15;
font-family: 'Asap';
font-weight: 700;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
font-size: 18px;

@media #{$large-up} {
font-size: 25px;

}
}
h5 {
color: $COL16;
font-family: 'Asap';
font-weight: 700;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
font-size: 17px;

@media #{$large-up} {
font-size: 20px;

}
}
h6 {
color: $COL17;
font-family: 'Asap';
font-weight: 700;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
font-size: 16px;

@media #{$medium-up} {
font-size: 18px;

}
}
.button {
font-family: 'Asap';
font-weight: 700;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
font-size: 18px;

}
.me-Quote .quote-body {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
.me-Quote .quote-author {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
ol,ul {
color: $COL11;
font-family: 'Asap';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 17px;

}
ol,ul { margin-bottom: 1em; list-style-position: outside; }
ol { margin-left: 1.25em; list-style-type: decimal; }
ul { margin-left: 1.25em; list-style-type: disc; }
ul, ol { & & { margin-bottom: 0 } }
cite {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 18px;

@media #{$medium-up} {
font-size: 20px;

}
}
summary {
font-family: 'Asap';
font-weight: 700;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
font-size: 18px;

@media #{$large-up} {
font-size: 25px;

}
}
nav.mainmenu > .menu-item > a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.mainmenu > .menu-item > div > a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.secondarymenu > .menu-item > a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.me-RespMenu.responsive-menu a {
font-family: 'Asap';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 20px;

}
body {
font-family: 'Asap';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 17px;

}
p { padding-bottom: 1em; }
a {color: $COL8}
/* Menu 3:32 */
.MES32 {
background-color: $COL28;
color: $COL20;
font-size: 17px;
padding: 0;

 }
h1.MEC32, h2.MEC32, h3.MEC32, h4.MEC32, h5.MEC32, h6.MEC32 { color: $COL20;
 }
a.MEC32 { color: $COL20;
&:hover { color: $COL5; }
 }
cite.MEC32{
color: $COL20;
font-size: 17px;
}
/* Alternate:25 */
.MES25 {
background-color: $COL24;
 }
/* Alternate:26 */
.MES26 {
background-color: $COL24;
 }
/* Menu 2:27 */
.MES27 {
background-color: $COL26;
color: $COL22;
font-size: 17px;
min-height:60px;
@media #{$medium-up} {
min-height: 80px;};
@media #{$large-up} {
min-height: 80px;};
border-width: 0 0 2px 0;
border-style: solid;
border-color: transparent transparent $COL5 transparent;
 }
h1.MEC27, h2.MEC27, h3.MEC27, h4.MEC27, h5.MEC27, h6.MEC27 { color: $COL22;
 }
a.MEC27 { color: $COL22;
&:hover { color: $COL5; }
 }
cite.MEC27{
color: $COL22;
font-size: 17px;
}
/* MENU:28 */
nav.me-Menu.MES28 {
& .menu-item.MEC28, & .menu-item.MEC28 > div.MEC28{ & > a.MEC28{color: $COL22;
font-size: 17px;
@media #{$medium-up} {
font-size: 18.7px;
}@media #{$large-up} {
font-size: 20.4px;
}text-transform: uppercase;
}
 &:hover > a.MEC28{color: $COL2;
}
 }
&.horizontal > .menu-item.MEC28 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC28 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC28 .menu-item { border:0;
border-bottom-width: 1px;
border-color: $COL1;
border-style: dotted;
 }
&.horizontal > .menu-item.MEC28 .sub-menu { border:1px;
 }
& .sub-menu .menu-item a{padding: 5px 10px;}


&.horizontal .menu-item.MEC28:hover {& > .pointer-wrap > .pointer{border-bottom: 10px solid $COL25;}}
&.vertical .menu-item.MEC28:hover {& > .pointer-wrap > .pointer{border-right: 10px solid $COL25;
}}

.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {
.menu-item.MEC28{background-color: $COL25;
 &:hover {background-color: $COL2}
}
& .menu-item.MEC28, & .menu-item.MEC28 > div.MEC28{ & > a.MEC28{color: $COL1;
text-transform: uppercase;
}
 &:hover > a.MEC28{color: $COL1;
}
 }

}
}
 }
/* Paralax panle:29 */
.MES29 {
& > .underlay, & > .inner-overlay { opacity: 0.9999 }
& {overflow: hidden; }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://abbin.com.au/img/736/31');
background-position: center center;
background-repeat: no-repeat;
top: -5%;
height: 130%;
background-size: cover;
min-height: 0.1px;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: $COL20;
min-height:200px;
@media #{$medium-up} {
min-height: 300px;};
@media #{$large-up} {
min-height: 400px;};
 }
cite.MEC29{
color: $COL20;
}
/* Enquiry Form:30 */
.me-block.me-Form.MES30,  body.MES30 {background-color:transparent;
 color: $COL20;
 
 input[type='submit']{border-style: solid;background-color: $COL23;
&:hover {background-color: $COL23}
border-color: $COL20;
&:hover {border-color: $COL20}
color: $COL20;
&:hover {color: $COL20}
padding: 10px 40px;

border-radius: 3px;
}
@include placeholder($COL20);
input:not([type='submit']), textarea, select {border-style: solid;
border-width: 1px;
border-color: $COL20;
background-color: $COL27;
color: $COL20;
}
::-webkit-input-placeholder { color: $COL20; opacity: 0.5;}
:-moz-placeholder {color: $COL20; opacity: 0.5;} 
::-moz-placeholder {color: $COL20; opacity: 0.5;}
:-ms-input-placeholder {color: $COL20; opacity: 0.5;}

  }
/* Product Separator:31 */
.MES31 {
& > hr {border-width: 1px 0 0 0;}
& > hr {border-color: $COL6;}
& > hr {border-top-style: solid;}
 }
/* MENU white:33 */
nav.me-Menu.MES33 {
& .menu-item.MEC33, & .menu-item.MEC33 > div.MEC33{ & > a.MEC33{color: $COL1;
font-size: 17px;
text-transform: uppercase;
}
 &:hover > a.MEC33{color: $COL5;
}
 }
&.horizontal > .menu-item.MEC33 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC33 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC33 .menu-item { border:0;
border-bottom-width: 1px;
border-color: $COL1;
border-style: dotted;
 }
&.horizontal > .menu-item.MEC33 .sub-menu { border:1px;
 }
& .sub-menu .menu-item a{padding: 5px 10px;}


&.horizontal .menu-item.MEC33:hover {& > .pointer-wrap > .pointer{border-bottom: 10px solid $COL25;}}
&.vertical .menu-item.MEC33:hover {& > .pointer-wrap > .pointer{border-right: 10px solid $COL25;
}}

.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {
.menu-item.MEC33{background-color: $COL25;
 &:hover {background-color: $COL2}
}
& .menu-item.MEC33, & .menu-item.MEC33 > div.MEC33{ & > a.MEC33{color: $COL1;
text-transform: uppercase;
}
 &:hover > a.MEC33{color: $COL1;
}
 }

}
}
 }
/* Home FAQ:23 */
details.MES23 {
& > summary{background-color: $COL2;
}
color: $COL20;
& > article { color: $COL20;
 }
& > summary{padding: 10px 0;}

& > article {border-width: 0 0 1px 0;
border-style: solid;
border-color: transparent transparent $COL20 transparent;
} }
/* Page image block:34 */
.MES34 {
background-color: $COL22;
& > .underlay, & > .inner-overlay { opacity: 0.3 }
& {overflow: hidden; }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://abbin.com.au/img/1214/137');
background-position: bottom center;
background-repeat: no-repeat;
top: -5%;
height: 130%;
background-size: cover;
min-height: 0.1px;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: $COL20;
min-height:400px;
@media #{$medium-up} {
min-height: 400px;};
@media #{$large-up} {
min-height: 400px;};
padding: 15px 30px;

 }
h1.MEC34, h2.MEC34, h3.MEC34, h4.MEC34, h5.MEC34, h6.MEC34 { color: $COL20;
 }
.MEC34 li {color: $COL20;}
cite.MEC34{
color: $COL20;
}
/* Page divider:35 */
.MES35 {
& > hr {border-width: 1px 0 0 0;}
& > hr {border-color: $COL24 transparent transparent transparent;}
& > hr {border-top-style: dotted;}
 }
/* Category Menu:36 */
nav.me-Menu.MES36 {
.menu-item.MEC36{background-color: $COL2;
 &:hover {background-color: $COL24}
}
& .menu-item.MEC36, & .menu-item.MEC36 > div.MEC36{ & > a.MEC36{color: $COL20;
text-transform: capitalize;
}
 &:hover > a.MEC36{color: $COL4;
}
 }
&.horizontal > .menu-item.MEC36 { border:0;
border-color: $COL20;
border-style: solid;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC36 { border:0;
border-color: $COL20;
border-style: solid;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC36 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC36 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 10px;}

& .sub-menu .menu-item a{padding: 5px 10px;}



.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {

}
}
 }
/* home blocks:37 */
.MES37 {
background-color: $COL20;
 }
/* Enquire:38 */
.MES38 {
background-color: $COL2;
color: $COL9;
padding: 10px 30px;

@media #{$medium-up} {
padding: 10px 50px;

}
 }
/* Responsive menu:39 */
nav.responsive-menu {
.menu-item.MEC39{background-color: $COL25;
}
& .menu-item.MEC39, & .menu-item.MEC39 > div.menu-item-wrap{ & > a.MEC39, & > i{color: $COL20;
font-size: 22.1px;
@media #{$medium-up} {
font-size: 17px;
}text-align: left;
}
  }
& .menu-item.MEC39 { border:0;

 border-bottom-width: 1px; }
& .menu-item > .menu-item-wrap {padding: 15px;}

& .sub-menu{ .sub-menu {}}

 }
/* Green colour:40 */
.MES40 {
background-color: $COL2;
color: $COL20;
font-size: 17px;
padding: 15px;

@media #{$medium-up} {
padding: 20px;

}
@media #{$large-up} {
padding: 20px 40px;

}
 }
a.MEC40 { color: $COL3;
&:hover { color: $COL22; }
 }
cite.MEC40{
color: $COL20;
font-size: 17px;
}
/* Secondary:24 */
.MES24 {
color: $COL22;
border-width: 2px;
border-style: solid;
border-color: $COL20;
 }
/* About FAQ:22 */
details.MES22 {
& > summary{background-color: $COL5;
}
& > summary{padding: 10px 0;}

& > article {border-width: 0 0 1px 0;
border-style: solid;
border-color: transparent transparent $COL20 transparent;
} }
/* Primary:3 */
.MES3 {
background-color: $COL2;
color: $COL9;
 }
/* Shade 1:11 */
.MES11 {
background-color: $COL6;
 }
/* Primary:4 */
.MES4 {
background-color: $COL2;
color: $COL20;
 }
h1.MEC4, h2.MEC4, h3.MEC4, h4.MEC4, h5.MEC4, h6.MEC4 { color: $COL20;
 }
a.MEC4 { color: $COL1;
 }
cite.MEC4{
color: $COL20;
}
/* Secondary:5 */
.MES5 {
background-color: $COL3;
color: $COL10;
 }
/* Secondary:6 */
.MES6 {
background-color: $COL3;
color: $COL10;
 }
h1.MEC6, h2.MEC6, h3.MEC6, h4.MEC6, h5.MEC6, h6.MEC6 { color: $COL10;
 }
cite.MEC6{
color: $COL10;
}
/* Dark:7 */
.MES7 {
background-color: $COL4;
color: $COL18;
 }
/* Dark:8 */
.MES8 {
background-color: $COL22;
color: $COL1;
 }
h1.MEC8, h2.MEC8, h3.MEC8, h4.MEC8, h5.MEC8, h6.MEC8 { color: $COL1;
 }
a.MEC8 { color: $COL5;
&:hover { color: $COL3; }
 }
cite.MEC8{
color: $COL1;
}
/* Light:9 */
.MES9 {
background-color: $COL5;
 }
/* Light:10 */
.MES10 {
background-color: $COL5;
padding: 20px;

@media #{$medium-up} {
padding: 20px 30px;

}
 }
/* Shade 1:12 */
.MES12 {
background-color: $COL6;
 }
/* News grid:21 */
.MES21 {
color: $COL4;
border-width: 1px;
border-style: solid;
border-color: $COL1 $COL5;
 }
h1.MEC21, h2.MEC21, h3.MEC21, h4.MEC21, h5.MEC21, h6.MEC21 { color: $COL22;
 }
cite.MEC21{
color: $COL4;
}
/* Menu:13 */
.MES13 {
background-color: $COL25;
color: $COL20;
font-size: 17px;
min-height:60px;
@media #{$medium-up} {
min-height: 80px;};
@media #{$large-up} {
min-height: 80px;};
 }
h1.MEC13, h2.MEC13, h3.MEC13, h4.MEC13, h5.MEC13, h6.MEC13 { color: $COL20;
 }
a.MEC13 { color: $COL20;
&:hover { color: $COL5; }
 }
cite.MEC13{
color: $COL20;
font-size: 17px;
}
/* home block blue:14 */
.MES14 {
background-color: $COL22;
& > .underlay, & > .inner-overlay { opacity: 0.4 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://abbin.com.au/img/1148/136');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: $COL20;
min-height:400px;
@media #{$medium-up} {
min-height: 400px;};
@media #{$large-up} {
min-height: 700px;};
padding: 15px 30px;

 }
h1.MEC14, h2.MEC14, h3.MEC14, h4.MEC14, h5.MEC14, h6.MEC14 { color: $COL20;
 }
.MEC14 li {color: $COL20;}
cite.MEC14{
color: $COL20;
}
/* :15 */
.MES15 {
background-color: $COL20;
color: $COL4;
border-width: 5px 0 0 0;
border-style: solid;
border-color: $COL3 transparent transparent transparent;
 }
h1.MEC15, h2.MEC15, h3.MEC15, h4.MEC15, h5.MEC15, h6.MEC15 { color: $COL4;
 }
.MEC15 li {color: $COL4;}
cite.MEC15{
color: $COL4;
}
/* Hollaw:16 */
.MES16 {
color: $COL20;
&:hover { color: $COL20;}
border-width: 2px;
border-style: solid;
border-color: $COL20;
&:hover { border-color: $COL20; }
 }
/* :17 */
.MES17 {
& .slider-arrow {color: $COL20;
font-size: 100px;
@media #{$medium-up} {
font-size: 100px;
};
@media #{$large-up} {
font-size: 100px;
};
} }
/* Contents:18 */
.MES18 {
background-color: $COL20;
&:hover, &.hover { background-color: $COL20;}
color: $COL4;
font-size: 17px;
padding: 15px;

@media #{$medium-up} {
padding: 20px;

}
@media #{$large-up} {
padding: 20px 40px;

}
 }
a.MEC18 { color: $COL4;
&:hover { color: $COL4; }
 }
cite.MEC18{
color: $COL4;
font-size: 17px;
}
/* Slider text 2:19 */
.MES19 {
 }
h1.MEC19, h2.MEC19, h3.MEC19, h4.MEC19, h5.MEC19, h6.MEC19 { color: $COL20;
 }
h1.MEC19 { @media #{$medium-up} { font-size: 38.5px; }; }
h1.MEC19 { @media #{$large-up} { font-size: 72px; }; }
h2.MEC19 { @media #{$medium-up} { font-size: 27.5px; }; }
h2.MEC19 { @media #{$large-up} { font-size: 48px; }; }
h3.MEC19 { @media #{$medium-up} { font-size: 22px; }; }
h3.MEC19 { @media #{$large-up} { font-size: 36px; }; }
h4.MEC19 { @media #{$medium-up} { font-size: 19.8px; }; }
h4.MEC19 { @media #{$large-up} { font-size: 30px; }; }
h5.MEC19 { @media #{$medium-up} { font-size: 18.7px; }; }
h5.MEC19 { @media #{$large-up} { font-size: 24px; }; }
h6.MEC19 { @media #{$medium-up} { font-size: 19.8px; }; }
h6.MEC19 { @media #{$large-up} { font-size: 21.6px; }; }
/* Footer Dark:20 */
.MES20 {
background-color: $COL22;
color: $COL1;
font-size: 13.6px;
 }
h1.MEC20, h2.MEC20, h3.MEC20, h4.MEC20, h5.MEC20, h6.MEC20 { color: $COL1;
 }
a.MEC20 { color: $COL5;
&:hover { color: $COL3; }
 }
cite.MEC20{
color: $COL1;
font-size: 13.6px;
}
/* Homepage bg image:41 */
.MES41 {
background-color: $COL22;
&:hover, &.hover { background-color: $COL25;}
& > .underlay, & > .inner-overlay { opacity: 0.3 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://abbin.com.au/img/1214/137');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: $COL20;
min-height:400px;
@media #{$medium-up} {
min-height: 400px;};
@media #{$large-up} {
min-height: 400px;};
padding: 15px 30px;

 }
h1.MEC41, h2.MEC41, h3.MEC41, h4.MEC41, h5.MEC41, h6.MEC41 { color: $COL20;
 }
.MEC41 li {color: $COL20;}
cite.MEC41{
color: $COL20;
}
