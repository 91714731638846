$COL1: #f2f2f2; //
$COLFLAT1: #f2f2f2; //
$COL2: #08a168; //
$COLFLAT2: #08a168; //
$COL3: #f0d167; //
$COLFLAT3: #f0d167; //
$COL4: #333333; //
$COLFLAT4: #333333; //
$COL5: #f2f2f2; //
$COLFLAT5: #f2f2f2; //
$COL6: #848484; //
$COLFLAT6: #848484; //
$COL7: #333333; //
$COLFLAT7: #333333; //
$COL8: #ffd714; //
$COLFLAT8: #ffd714; //
$COL9: #ffffff; //
$COLFLAT9: #ffffff; //
$COL10: #ffffff; //
$COLFLAT10: #ffffff; //
$COL11: #333333; //
$COLFLAT11: #333333; //
$COL12: #333333; //
$COLFLAT12: #333333; //
$COL13: #333333; //
$COLFLAT13: #333333; //
$COL14: #333333; //
$COLFLAT14: #333333; //
$COL15: #333333; //
$COLFLAT15: #333333; //
$COL16: #333333; //
$COLFLAT16: #333333; //
$COL17: #333333; //
$COLFLAT17: #333333; //
$COL18: #ffffff; //
$COLFLAT18: #ffffff; //
$COL19: #fefefe; //
$COLFLAT19: #fefefe; //
$COL20: #ffffff; //white
$COLFLAT20: #ffffff; //white
$COL21: rgba(8,8,8,0.62); //Dark transparent
$COLFLAT21: #363636; //Dark transparent
$COL22: #1b1b1b; //DARKER
$COLFLAT22: #1b1b1b; //DARKER
$COL23: rgba(0,0,0,0); //
$COLFLAT23: #808080; //
$COL24: #55cf85; //
$COLFLAT24: #55cf85; //
$COL25: rgba(8,161,104,0.69); //Green transparent
$COLFLAT25: #2d976f; //Green transparent
$COL26: rgba(255,255,255,0.8); //white 80%
$COLFLAT26: #e6e6e6; //white 80%
$COL27: rgba(255,255,255,0.302); //white 30%
$COLFLAT27: #a6a6a6; //white 30%
$COL28: rgba(27,26,26,0.278); //Dark Transparent Header
$COLFLAT28: #646464; //Dark Transparent Header
$breakpoints: (
  small: 0,
  medium: 640px,
  large: 1024px,
  xlarge: 1248px,
  xxlarge: 1936px,
);
$global-width: 1200px;
